import {
  ChainId,
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-xyz/sdk-core'
import {
  LB_SWAP_ROUTER_LOGIC,
  ODOS_ROUTER,
  OKX_TOKEN_SPENDER
} from 'constants/addresses'
import { LB_SWAP_ROUTER } from 'constants/addresses'
import JSBI from 'jsbi'
import {
  Aggregator,
  Pair,
  Route,
  Swap,
  Token as RouterToken
} from 'types/router'
import { encodePacked, getAddress, Hex, parseUnits, zeroAddress } from 'viem'

import { MerchantMoeChainId } from '../constants/chains'

type ChainTokenList = {
  readonly [chainId in MerchantMoeChainId]: Token[]
}

export const USDTe = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xAb231A5744C8E6c45481754928cCfFFFD4aa0732',
    6,
    'USDT.e',
    'Bridged USDT'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    6,
    'USDT',
    'Tether USD'
  )
}

export const USDCe = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0x3b3A66124Db1d4dFaEbD1A537740dbC0bb9A5181',
    6,
    'USDC.e',
    'Bridgec USDC'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    6,
    'USDC',
    'USD Coin'
  )
}

export const WBTC = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2',
    8,
    'WBTC',
    'Wrapped BTC'
  )
}

export const WETH = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    18,
    'WETH',
    'Wrapped ETH'
  )
}

export const mETH = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xcDA86A272531e8640cD7F1a92c01839911B90bb0',
    18,
    'mETH',
    'mETH'
  )
}

export const WNATIVE_ONLY: ChainTokenList = {
  [MerchantMoeChainId.FUJI]: [WNATIVE[MerchantMoeChainId.FUJI]],
  [MerchantMoeChainId.MANTLE]: [WNATIVE[MerchantMoeChainId.MANTLE]]
}

export const tryParseAmount = (
  value?: string,
  currency?: Currency,
  parseZero?: boolean
): CurrencyAmount | undefined => {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(
      value as `${number}`,
      currency.decimals
    ).toString()
    if (typedValueParsed !== '0' || parseZero) {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const getCurrencyAmount = (currency?: Currency, amount?: bigint) => {
  if (amount === undefined || !currency) return undefined
  return currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(amount.toString()))
    : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(amount.toString()))
}

export const getSwapCallParameters = ({
  chainId,
  currencyIn,
  currencyOut,
  deadline,
  isExactIn,
  isTransferTax,
  recipient,
  route,
  slippageBps
}: {
  chainId: MerchantMoeChainId
  currencyIn: Currency
  currencyOut: Currency
  deadline: number
  isExactIn: boolean
  isTransferTax: boolean
  recipient: string
  route: Route
  slippageBps: number
}) => {
  const isNativeIn = currencyIn.isNative
  const isNativeOut = currencyOut.isNative

  if (isNativeIn && isNativeOut) {
    throw new Error('ETHER_IN_OUT')
  }

  const to = getAddress(recipient)

  const tokenIn = currencyIn.isNative
    ? zeroAddress
    : getAddress(route.tokenIn.address)
  const tokenOut = currencyOut.isNative
    ? zeroAddress
    : getAddress(route.tokenOut.address)

  const encodedRoute = encodePackedRouteForTrade(
    route,
    isExactIn,
    isTransferTax
  )

  let amountIn: bigint
  let amountOut: bigint

  if (isExactIn) {
    amountIn = route.amountIn.value
    amountOut =
      route.amountOut.value -
      (route.amountOut.value * BigInt(slippageBps)) / BigInt(10_000)
  } else {
    amountIn =
      route.amountIn.value +
      (route.amountIn.value * BigInt(slippageBps)) / BigInt(10_000)
    amountOut = route.amountOut.value
  }

  let methodName: 'swapExactIn' | 'swapExactOut'
  let args
  let value: bigint

  if (isExactIn) {
    methodName = 'swapExactIn'
    args = [
      LB_SWAP_ROUTER_LOGIC[chainId],
      tokenIn,
      tokenOut,
      amountIn,
      amountOut,
      to,
      BigInt(deadline),
      encodedRoute
    ] as const
    value = isNativeIn ? amountIn : BigInt(0)
  } else {
    methodName = 'swapExactOut'
    args = [
      LB_SWAP_ROUTER_LOGIC[chainId],
      tokenIn,
      tokenOut,
      amountOut,
      amountIn,
      to,
      BigInt(deadline),
      encodedRoute
    ] as const
    value = isNativeIn ? amountIn : BigInt(0)
  }

  return {
    args,
    methodName,
    value
  }
}

export function getRouteParams(
  route: Route,
  isExactIn: boolean
): {
  pairs: [`0x${string}`, number, number, number, number][]
  tokens: `0x${string}`[]
} {
  const tokenPath: RouterToken[] = []
  const allSwaps: Swap[] = []

  function traverseRoute(currentToken: RouterToken) {
    if (
      !tokenPath.some(
        (token) =>
          token.address.toLowerCase() === currentToken.address.toLowerCase()
      )
    ) {
      tokenPath.push(currentToken)
    }
    if (currentToken.swaps && currentToken.swaps.length > 0) {
      for (const swap of currentToken.swaps) {
        allSwaps.push(swap)
        traverseRoute(swap.tokenOut)
      }
    }
  }

  traverseRoute(route.tokenIn)

  // Ensure the final token out is always last in the token path
  const finalTokenOut = route.tokenOut
  const finalTokenOutIndex = tokenPath.findIndex(
    (token) =>
      token.address.toLowerCase() === finalTokenOut.address.toLowerCase()
  )
  if (
    finalTokenOutIndex !== -1 &&
    finalTokenOutIndex !== tokenPath.length - 1
  ) {
    const [removedToken] = tokenPath.splice(finalTokenOutIndex, 1)
    tokenPath.push(removedToken)
  } else if (finalTokenOutIndex === -1) {
    tokenPath.push(finalTokenOut)
  }

  const pairs = allSwaps.reduce(
    (acc, swap) => {
      const tokenInIndex = tokenPath.findIndex(
        (t) => t.address.toLowerCase() === swap.tokenIn.address.toLowerCase()
      )
      const tokenOutIndex = tokenPath.findIndex(
        (t) => t.address.toLowerCase() === swap.tokenOut.address.toLowerCase()
      )

      const zeroForOne = swap.pair.tokenX === swap.tokenIn.address

      const existingPairIndex = acc.findIndex(
        (p) => p[0] === getAddress(swap.pair.address)
      )

      if (existingPairIndex !== -1) {
        // Update existing pair
        acc[existingPairIndex][1] = Math.min(
          Math.max(acc[existingPairIndex][1], swap.amountBp),
          10000
        )
      } else {
        // Add new pair
        acc.push([
          getAddress(swap.pair.address),
          swap.amountBp,
          getFlags(swap.pair, zeroForOne),
          tokenInIndex,
          tokenOutIndex
        ])
      }

      return acc
    },
    [] as [Hex, number, number, number, number][]
  )

  const sortedPairs = pairs.sort((a, b) => {
    if (a[3] === b[3]) return a[4] - b[4]
    if (b[3] === a[4]) return -1
    if (a[3] === b[4]) return 1
    return a[3] - b[3]
  })

  let recalculatedPairs: [Hex, number, number, number, number][] = []

  if (isExactIn) {
    recalculatedPairs = sortedPairs.reduce(
      (acc, pair) => {
        const [address, percent, flags, tokenInIndex, tokenOutIndex] = pair
        let newPercent = percent

        const relatedPairs = sortedPairs.filter((p) => p[3] === tokenInIndex)
        if (relatedPairs.length > 1) {
          const currentPairIndex = relatedPairs.indexOf(pair)
          if (currentPairIndex === relatedPairs.length - 1) {
            newPercent = 10000
          } else {
            const previousTotal = relatedPairs
              .slice(0, currentPairIndex)
              .reduce((sum, p) => sum + p[1], 0)
            newPercent = Math.round((percent / (10000 - previousTotal)) * 10000)
          }
        }

        acc.push([address, newPercent, flags, tokenInIndex, tokenOutIndex])
        return acc
      },
      [] as [Hex, number, number, number, number][]
    )
  } else {
    sortedPairs.reverse()

    recalculatedPairs = sortedPairs.reduce(
      (acc, pair) => {
        const [address, percent, flags, tokenInIndex, tokenOutIndex] = pair
        let newPercent = percent

        const relatedPairs = sortedPairs.filter((p) => p[4] === tokenOutIndex)

        if (relatedPairs.length >= 1) {
          const currentPairIndex = relatedPairs.indexOf(pair)
          if (currentPairIndex === relatedPairs.length - 1) {
            newPercent = 10000
          } else {
            const previousTotal = relatedPairs
              .slice(0, currentPairIndex)
              .reduce((sum, p) => sum + p[1], 0)
            newPercent = Math.round((percent / (10000 - previousTotal)) * 10000)
          }
        }

        acc.push([address, newPercent, flags, tokenInIndex, tokenOutIndex])
        return acc
      },
      [] as [Hex, number, number, number, number][]
    )
  }

  if (!isExactIn) {
    recalculatedPairs.reverse()
  }

  const tokens = tokenPath.map((token) => getAddress(token.address))

  return {
    pairs: recalculatedPairs,
    tokens
  }
}

export function encodePackedRouteForTrade(
  route: Route,
  isExactIn: boolean,
  isTransferTax: boolean
): Hex {
  const { pairs, tokens } = getRouteParams(route, isExactIn)

  const encodedPairs = pairs.map((pair) =>
    encodePacked(['address', 'uint16', 'uint16', 'uint8', 'uint8'], pair)
  )

  const encodedTokens = encodePacked(
    ['uint8', 'bool', ...Array(tokens.length).fill('address')],
    [tokens.length, isTransferTax, ...tokens]
  )

  return encodePacked(
    ['bytes', ...Array(encodedPairs.length).fill('bytes')],
    [encodedTokens, ...encodedPairs]
  )
}

function getFlags(pair: Pair, zeroForOne: boolean): number {
  let flags = 0

  if (zeroForOne) {
    flags |= 1
  }

  switch (pair.type) {
    case 'v1':
      flags |= 1 << 8 // UNISWAP_V2_ID
      break
    case 'v2':
      switch (pair.data.lbType) {
        case 'v2':
          flags |= 2 << 8 // TRADERJOE_LEGACY_LB_ID
          break
        case 'v2.1':
        case 'v2.2':
          flags |= 3 << 8 // TRADERJOE_LB_ID
          break
      }
      break
  }

  return flags
}

export const getSpenderForAggregator = (
  aggregator: Aggregator,
  chainId: MerchantMoeChainId
): string | undefined => {
  switch (aggregator) {
    case 'okx':
      return OKX_TOKEN_SPENDER[chainId]
    case 'jar':
      return LB_SWAP_ROUTER[chainId]
    case 'odos':
      return ODOS_ROUTER[chainId]
  }
}
