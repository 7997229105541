import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useAccount } from 'wagmi'

interface JourneymanTrialsRewards {
  finalMoeStake: number
  mntRewards: number
  moeIncrease: number
  moeTarget: number
  userAddress: string
}

const useJourneymanTrialsRewards = () => {
  const { address } = useAccount()
  const fetchTrialsRewards = useDexbarnGet<JourneymanTrialsRewards>(
    `/v1/user/journeyman-trials-rewards/${address}`
  )

  return useQuery({
    enabled: !!address,
    queryFn: () => fetchTrialsRewards(),
    queryKey: ['JourneymanTrialsRewards', address]
  })
}

export default useJourneymanTrialsRewards
