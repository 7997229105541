import useChainId from 'hooks/useChainId'
import { Route } from 'types/router'

import useFetchTokensList from '../tokensList/useFetchTokensList'

interface IsTrustedTradeOutput {
  inputTrusted: boolean
  outputTrusted: boolean
}

const useIsTrustedTrade = (trade: Route): IsTrustedTradeOutput => {
  const chainId = useChainId()
  const { data, isLoading } = useFetchTokensList()

  // If loading, assume trusted so trusted tokens (common case) don't get
  // momentarily mis-flagged as untrusted while the token list loads
  if (isLoading) {
    return { inputTrusted: true, outputTrusted: true }
  }

  const tokensList =
    data?.tokens
      .filter((token) => token.chainId === chainId && token.address != null)
      .map((token) => token.address?.toLowerCase())
      .filter(Boolean) || []

  const inputTrusted =
    trade.currencyIn.isNative ||
    tokensList.includes(trade.currencyIn.address.toLowerCase())
  const outputTrusted =
    trade.currencyOut.isNative ||
    tokensList.includes(trade.currencyOut.address.toLowerCase())

  return { inputTrusted, outputTrusted }
}

export default useIsTrustedTrade
