import { t } from '@lingui/macro'
import { MAX_UINT256 } from 'constants/bigint'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { erc20Abi, getAddress, parseAbi } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

import useAddRecentTransaction from './useAddRecentTransaction'
import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'
import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

export type TokenApprovalType = 'one_time' | 'unlimited'

interface UseApproveSpenderIfNeededProps {
  amount?: bigint
  spender?: string
  token?: string
  tokenSymbol?: string
}

const useApproveSpenderIfNeeded = ({
  amount: transactionAmount,
  spender,
  token,
  tokenSymbol
}: UseApproveSpenderIfNeededProps) => {
  const { address: account, chain: walletChain } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const [approvalType, setApprovalType] =
    useState<TokenApprovalType>('one_time')
  const amount = useMemo(
    () => (approvalType === 'unlimited' ? MAX_UINT256 : transactionAmount),
    [approvalType, transactionAmount]
  )

  const {
    data: allowance,
    isLoading: isLoadingAllowance,
    refetch: refetchAllowance
  } = useReadContract({
    abi: erc20Abi,
    address: token ? getAddress(token) : undefined,
    args: account && spender ? [account, getAddress(spender)] : undefined,
    chainId,
    functionName: 'allowance',
    query: {
      enabled: !!account && amount !== undefined && !!spender,
      gcTime: 0
    }
  })
  const debouncedRefetchAllowance = debounce(refetchAllowance, 1000)

  const isInitiallyApproved = useMemo(
    () =>
      allowance !== undefined && transactionAmount !== undefined
        ? allowance >= transactionAmount
        : undefined,
    [allowance, transactionAmount]
  )

  const { data: config } = useSimulateContract({
    abi: parseAbi(['function approve(address spender, uint256 amount)']),
    address: token ? getAddress(token) : undefined,
    args: spender && amount ? [getAddress(spender), amount] : undefined,
    chainId,
    functionName: 'approve',
    query: {
      enabled:
        !isInitiallyApproved &&
        amount !== undefined &&
        allowance !== undefined &&
        walletChain?.id === chainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    reset,
    writeContract,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Approve ${tokenSymbol}`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const approve = config?.request
    ? () => writeContract(config.request)
    : undefined
  const approveAsync = config?.request
    ? () => writeContractAsync(config.request)
    : undefined

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransactionReceipt({
    hash,
    onTransactionSuccess: () => debouncedRefetchAllowance()
  })

  return {
    approvalType,
    approve,
    approveAsync,
    isApproved: isInitiallyApproved || receipt?.status === 'success',
    isApproving: isWaitingForTransaction || isPending,
    isLoadingAllowance,
    isSuccess,
    refetchAllowance,
    reset,
    setApprovalType
  }
}

export default useApproveSpenderIfNeeded
