import { t } from '@lingui/macro'
import { LBSwapRouterAbi } from 'constants/abis/lbSwapRouter'
import { FORWARDER_LOGIC_ADDRESS, LB_SWAP_ROUTER } from 'constants/addresses'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useEffect, useMemo } from 'react'
import { Aggregator } from 'types/router'
import { isUserRejectedError } from 'utils/error'
import { formattedNum } from 'utils/format'
import { getSpenderForAggregator } from 'utils/swap'
import { encodePacked, getAddress, zeroAddress } from 'viem'
import { BaseError, useAccount, useWalletClient, useWriteContract } from 'wagmi'

import useGetSwapDataFromBarn from './useGetSwapDataFromBarn'
import { UseSwapProps, UseSwapResult } from './useSwap'

interface UseSwapWithExternalAggregatorProps extends UseSwapProps {
  aggregator?: Aggregator
}

const useSwapWithExternalAggregator = ({
  aggregator,
  currencyIn,
  currencyOut,
  enabled,
  onSwapSuccess,
  route,
  slippageBps
}: UseSwapWithExternalAggregatorProps): UseSwapResult => {
  const chainId = useChainId()
  const { address: userAddress } = useAccount()
  const { data: walletClient } = useWalletClient()
  const getTransactionDeadline = useGetTransactionDeadline()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    data: swapData,
    error: swapDataError,
    isLoading: isFetchingSwapData
  } = useGetSwapDataFromBarn({
    aggregator,
    amountIn: route?.amountIn.value.toString(),
    currencyIn,
    currencyOut,
    enabled,
    slippageBps
  })

  const {
    data: hash,
    error: writeContractError,
    isPending,
    reset,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!route) return

        const transactionSummary = t`Swap ${formattedNum(
          route?.amountIn.formatted
        )} ${currencyIn?.symbol} for ${formattedNum(
          route?.amountOut.formatted
        )} ${currencyOut?.symbol}`

        addRecentTransaction({ description: transactionSummary, hash })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  useEffect(() => {
    reset()
  }, [swapData, reset])

  const swapArgs = useMemo(() => {
    const spenderAddress = route
      ? getSpenderForAggregator(route.aggregator, chainId)
      : undefined

    const deadline = getTransactionDeadline()

    if (
      !currencyIn ||
      !currencyOut ||
      !route ||
      !userAddress ||
      !swapData ||
      !spenderAddress ||
      !deadline
    ) {
      return undefined
    }

    const amountOutMin =
      route.amountOut.value -
      (route.amountOut.value * BigInt(slippageBps)) / BigInt(10_000)

    return [
      FORWARDER_LOGIC_ADDRESS[chainId],
      currencyIn.isToken ? getAddress(currencyIn.address) : zeroAddress,
      currencyOut.isToken ? getAddress(currencyOut.address) : zeroAddress,
      route.amountIn.value,
      amountOutMin,
      userAddress,
      deadline,
      encodePacked(
        ['address', 'address', 'bytes'],
        [
          getAddress(spenderAddress),
          swapData.to as `0x${string}`,
          swapData.data as `0x${string}`
        ]
      )
    ] as const
  }, [
    chainId,
    currencyIn,
    currencyOut,
    route,
    swapData,
    userAddress,
    getTransactionDeadline,
    slippageBps
  ])

  const swapAsync = swapArgs
    ? async () => {
        try {
          const hash = await writeContractAsync({
            abi: LBSwapRouterAbi,
            address: LB_SWAP_ROUTER[chainId],
            args: swapArgs,
            functionName: 'swapExactIn',
            value: currencyIn?.isNative ? swapArgs[3] : undefined
          })
          return hash
        } catch (error) {
          console.error('Error sending transaction', error)
          throw error
        }
      }
    : undefined

  const { data: receipt, isLoading: isReceiptLoading } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSwapSuccess
    })

  const error = useMemo(() => {
    if (swapDataError) {
      return {
        message: swapDataError.response?.data.error || '',
        summary: swapDataError.message
      }
    }

    if (
      writeContractError &&
      !isUserRejectedError(writeContractError.message)
    ) {
      return {
        message: writeContractError.message,
        summary: (writeContractError as BaseError).shortMessage
      }
    }

    return undefined
  }, [swapDataError, writeContractError])

  const forceSwapAsync =
    swapArgs && walletClient && !!error
      ? async () => {
          return walletClient.writeContract({
            abi: LBSwapRouterAbi,
            address: LB_SWAP_ROUTER[chainId],
            args: swapArgs,
            functionName: 'swapExactIn',
            value: currencyIn?.isNative ? swapArgs[3] : undefined
          })
        }
      : undefined

  return {
    error,
    forceSwapAsync,
    isSimulating: isFetchingSwapData,
    isSwapping: isPending || isReceiptLoading,
    receipt,
    resetSwap: reset,
    swapAsync
  }
}

export default useSwapWithExternalAggregator
