import { Currency } from '@traderjoe-xyz/sdk-core'
import { Route } from 'types/router'
import { TransactionReceipt } from 'viem'

import useSwapWithExternalAggregator from './useSwapWithExternalAggregator'
import useSwapWithJoeAggregator from './useSwapWithJoeAggregator'

export interface UseSwapProps {
  enabled: boolean
  isExactIn: boolean
  onSwapSuccess: () => void
  slippageBps: number
  currencyIn?: Currency
  currencyOut?: Currency
  route?: Route
}

export interface UseSwapResult {
  isSimulating: boolean
  isSwapping: boolean
  error?: {
    message: string
    summary: string | undefined
  }
  forceSwapAsync?: () => Promise<`0x${string}`> | undefined
  receipt?: TransactionReceipt | undefined
  resetSwap?: () => void
  swapAsync?: () => Promise<`0x${string}`> | undefined
}

export const useSwap = (props: UseSwapProps): UseSwapResult => {
  const aggregator = props.route?.aggregator

  const joeAggregatorResult = useSwapWithJoeAggregator({
    ...props,
    enabled: props.enabled && aggregator === 'jar'
  })

  const externalAggregatorResult = useSwapWithExternalAggregator({
    ...props,
    aggregator,
    enabled: props.enabled && aggregator !== 'jar'
  })

  switch (aggregator) {
    case 'jar':
      return joeAggregatorResult
    case 'okx':
    case 'odos':
      return externalAggregatorResult
    case undefined:
      return {
        isSimulating: false,
        isSwapping: false
      }
  }
}
