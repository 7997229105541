import { useQuery } from '@tanstack/react-query'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { AxiosError } from 'axios'
import { FORWARDER_LOGIC_ADDRESS } from 'constants/addresses'
import { routerApiClient, routerTestnetClient } from 'constants/dexbarn'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import { Aggregator, SwapData } from 'types/router'
import { getDexbarnChainParam } from 'utils/chains'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

interface UseGetSwapDataFromBarnProps {
  slippageBps: number
  aggregator?: Aggregator
  amountIn?: string
  currencyIn?: Currency
  currencyOut?: Currency
  enabled?: boolean
}

const useGetSwapDataFromBarn = ({
  aggregator,
  amountIn,
  currencyIn,
  currencyOut,
  enabled,
  slippageBps
}: UseGetSwapDataFromBarnProps) => {
  const chainId = useChainId()
  const chain = useActiveChain()
  const client = chain.testnet ? routerTestnetClient : routerApiClient

  const { isConnected } = useAccount()

  const fromTokenAddress = currencyIn?.isNative
    ? wrappedCurrency(currencyIn, chainId)?.address
    : currencyIn?.address

  const toTokenAddress = currencyOut?.isNative
    ? wrappedCurrency(currencyOut, chainId)?.address
    : currencyOut?.address

  const params =
    fromTokenAddress && toTokenAddress && amountIn
      ? {
          amountIn,
          slippageBps,
          tokenIn: fromTokenAddress.toLowerCase(),
          tokenOut: toTokenAddress.toLowerCase(),
          userAddress: FORWARDER_LOGIC_ADDRESS[chainId]
        }
      : undefined

  return useQuery<SwapData, AxiosError<{ error: string }>>({
    enabled: enabled && !!params && !!aggregator && isConnected,
    queryFn: async () => {
      const response = await client.get<SwapData>(
        `/v2/aggregator/routes/${getDexbarnChainParam(
          chainId
        )}/${aggregator}/swap`,
        { params }
      )
      return response.data
    },
    queryKey: ['GetSwapData', chainId, params, aggregator]
  })
}

export default useGetSwapDataFromBarn
