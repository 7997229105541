import { Icon, IconProps } from '@chakra-ui/react'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { ReactComponent as ArrowLeftRight } from 'assets/arrow-left-right.svg'
import { ReactComponent as ArrowUpDown } from 'assets/arrow-up-down.svg'
import { ReactComponent as Bridge } from 'assets/bridge.svg'
import { ReactComponent as Candlestick } from 'assets/candlestick.svg'
import { ReactComponent as CoinMarketCap } from 'assets/coinmarketcap.svg'
import { ReactComponent as Copy } from 'assets/copy.svg'
import { ReactComponent as Disconnect } from 'assets/disconnect.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'
import { ReactComponent as GKey } from 'assets/g-key.svg'
import { ReactComponent as Github } from 'assets/github.svg'
import { ReactComponent as Hamburger } from 'assets/hamburger.svg'
import { ReactComponent as Keyboard } from 'assets/keyboard.svg'
import { ReactComponent as Link } from 'assets/link.svg'
import { ReactComponent as Moon } from 'assets/moon.svg'
import { ReactComponent as MyActivity } from 'assets/my-activity.svg'
import { ReactComponent as MyPool } from 'assets/my-pool.svg'
import { ReactComponent as MySettings } from 'assets/my-settings.svg'
import { ReactComponent as PieChart } from 'assets/pie-chart.svg'
import { ReactComponent as Pool } from 'assets/pool-icon.svg'
import { ReactComponent as Refresh } from 'assets/refresh.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import { ReactComponent as Settings } from 'assets/settings.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { ReactComponent as TKey } from 'assets/t-key.svg'
import { ReactComponent as ThreeDots } from 'assets/three-dots.svg'
import { ReactComponent as Trade } from 'assets/trade.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as UKey } from 'assets/u-key.svg'
import { ReactComponent as Wallet } from 'assets/wallet.svg'
import { ReactComponent as Warning } from 'assets/warning.svg'
import { ReactComponent as Zap } from 'assets/zap.svg'
import React from 'react'

const ArrowUpDownIcon = (props: IconProps) => (
  <Icon as={ArrowUpDown} fill="textPrimary" {...props} />
)

const ArrowLeftRightIcon = (props: IconProps) => (
  <Icon as={ArrowLeftRight} fill="textPrimary" {...props} />
)

const SettingsIcon = (props: IconProps) => (
  <Icon as={Settings} fill="textPrimary" {...props} />
)

const MoonIcon = (props: IconProps) => (
  <Icon as={Moon} stroke="textPrimary" {...props} />
)

const SunIcon = (props: IconProps) => (
  <Icon as={Sun} fill="textPrimary" {...props} />
)

const ThreeDotsIcon = (props: IconProps) => (
  <Icon as={ThreeDots} fill="textPrimary" {...props} />
)

const DiscordIcon = (props: IconProps) => (
  <Icon as={Discord} fill="textPrimary" {...props} />
)

const TwitterIcon = (props: IconProps) => (
  <Icon as={Twitter} fill="textPrimary" {...props} />
)

const GithubIcon = (props: IconProps) => (
  <Icon as={Github} fill="textPrimary" {...props} />
)

const CoinMarketCapIcon = (props: IconProps) => (
  <Icon as={CoinMarketCap} fill="textPrimary" {...props} />
)

const WalletIcon = (props: IconProps) => (
  <Icon as={Wallet} fill="textPrimary" {...props} />
)

const TradeIcon = (props: IconProps) => (
  <Icon as={Trade} fill="textPrimary" {...props} />
)

const PoolIcon = (props: IconProps) => (
  <Icon as={Pool} fill="textPrimary" {...props} />
)

const RefreshIcon = (props: IconProps) => (
  <Icon as={Refresh} fill="textPrimary" {...props} />
)

const HamburgerIcon = (props: IconProps) => (
  <Icon as={Hamburger} fill="textPrimary" {...props} />
)

const SearchIcon = (props: IconProps) => (
  <Icon as={Search} fill="textPrimary" {...props} />
)

const KeyboardIcon = (props: IconProps) => (
  <Icon as={Keyboard} fill="textPrimary" {...props} />
)

const GKeyIcon = (props: IconProps) => (
  <Icon as={GKey} fill="textPrimary" {...props} />
)

const TKeyIcon = (props: IconProps) => (
  <Icon as={TKey} fill="textPrimary" {...props} />
)

const UKeyIcon = (props: IconProps) => (
  <Icon as={UKey} fill="textPrimary" {...props} />
)

const CopyIcon = (props: IconProps) => (
  <Icon as={Copy} fill="textPrimary" {...props} />
)

const LinkIcon = (props: IconProps) => (
  <Icon as={Link} fill="textPrimary" {...props} />
)

const MyPoolIcon = (props: IconProps) => (
  <Icon as={MyPool} fill="textPrimary" {...props} />
)

const MyActivityIcon = (props: IconProps) => (
  <Icon as={MyActivity} fill="textPrimary" {...props} />
)

const MySettingsIcon = (props: IconProps) => (
  <Icon as={MySettings} fill="textPrimary" {...props} />
)

const ArrowLeftIcon = (props: IconProps) => (
  <Icon as={ArrowLeft} fill="textPrimary" {...props} />
)

const WarningIcon = (props: IconProps) => (
  <Icon as={Warning} fill="textPrimary" {...props} />
)

const DisconnectIcon = (props: IconProps) => (
  <Icon as={Disconnect} fill="red.500" {...props} />
)

const PieChartIcon = (props: IconProps) => (
  <Icon as={PieChart} fill="textPrimary" {...props} />
)

const BridgeIcon = (props: IconProps) => (
  <Icon as={Bridge} fill="textPrimary" {...props} />
)

const ZapIcon = (props: IconProps) => (
  <Icon as={Zap} fill="textPrimary" {...props} />
)

const CandlestickIcon = (props: IconProps) => (
  <Icon as={Candlestick} fill="textPrimary" {...props} />
)

export {
  ArrowLeftIcon,
  ArrowLeftRightIcon,
  ArrowUpDownIcon,
  BridgeIcon,
  CandlestickIcon,
  CoinMarketCapIcon,
  CopyIcon,
  DisconnectIcon,
  DiscordIcon,
  GithubIcon,
  GKeyIcon,
  HamburgerIcon,
  KeyboardIcon,
  LinkIcon,
  MoonIcon,
  MyActivityIcon,
  MyPoolIcon,
  MySettingsIcon,
  PieChartIcon,
  PoolIcon,
  RefreshIcon,
  SearchIcon,
  SettingsIcon,
  SunIcon,
  ThreeDotsIcon,
  TKeyIcon,
  TradeIcon,
  TwitterIcon,
  UKeyIcon,
  WalletIcon,
  WarningIcon,
  ZapIcon
}
