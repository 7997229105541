import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import useJourneymanTrialsRewards from 'hooks/moeJourney/useJourneymanTrialsRewards'
import useMerkleProofRewards from 'hooks/moeJourney/useMerkleProofRewards'
import useGetUserMoeDepositedAmount from 'hooks/stake/useGetUserMoeDepositedAmount'
import React, { useState } from 'react'
import { formattedNum } from 'utils/format'

import { MOE_JOURNEY_REWARDER_ADDRESS } from './constants'

const MoeJourneyRewardReveal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleReveal = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const { data: trialsRewards } = useJourneymanTrialsRewards()
  const { data: userMoeDepositedAmount } = useGetUserMoeDepositedAmount()

  const { batchClaimRewards, claimableRewards, isClaiming, isLoading } =
    useMerkleProofRewards({
      market: '0x1111222233334444dEAddEAddEAdDeAdDeaDDEAd',
      rewarderAddress: MOE_JOURNEY_REWARDER_ADDRESS
    })

  const hasWon = trialsRewards && trialsRewards.mntRewards > 0
  const fmtRewards = hasWon ? `${trialsRewards?.mntRewards} MNT` : '0 MNT'
  const hasClaimedRewards = hasWon && claimableRewards?.length === 0

  const currentStake = Number(userMoeDepositedAmount?.formatted || 0)
  const targetStake = trialsRewards?.moeTarget || 0
  const finalMoeStake = trialsRewards?.finalMoeStake || 0

  const progress = Math.min(
    Math.max((currentStake - finalMoeStake) / (targetStake - finalMoeStake), 0),
    1
  )

  // Hide rewards section until Feb 18th 4PM UTC
  const revealTime = new Date('2025-02-19T16:00:00.000Z') // .000Z ensures UTC timezone
  if (Date.now() < revealTime.getTime()) {
    return null
  }

  return (
    <VStack
      w={{ base: 'full', md: '450px' }}
      mt={{ base: '40px', md: '100px' }}
      spacing={{ base: 2, md: 4 }}
    >
      <Heading size="lg">Rewards</Heading>
      <VStack bg="bgCard" p={4} borderRadius="2xl" boxShadow="element" w="full">
        {hasWon ? (
          <VStack align="flex-start">
            <Heading size="sm">Double Down Rewards</Heading>
            <Text textColor="textSecondary">
              Reach your MOE staking target to qualify for another 100K MNT
              Reward pool.{' '}
              <Link
                color="accent.500"
                href="https://x.com/MerchantMoe_xyz/status/1889104841622044895"
                isExternal
              >
                Learn More.
              </Link>
            </Text>

            <VStack align="flex-start" w="full" spacing={1} pt={2}>
              <HStack justify="space-between" w="full" fontSize="sm">
                <Text>{formattedNum(currentStake)} MOE</Text>
                <Text>{formattedNum(targetStake)} MOE</Text>
              </HStack>
              <Progress
                min={0}
                max={1}
                value={progress}
                colorScheme="accent"
                borderRadius="full"
                size="sm"
                w="full"
                bg="gray.200"
                _dark={{
                  bg: 'gray.700'
                }}
              />
            </VStack>
          </VStack>
        ) : (
          <VStack align="flex-start" w="full">
            <Heading size="sm">That&apos;s a wrap Journeyman!</Heading>
            <Text>Sorry journeyman, you have no rewards to claim.</Text>
          </VStack>
        )}

        <Button
          mt={4}
          variant="solid"
          colorScheme="accent"
          onClick={handleReveal}
          size="lg"
          h="48px"
          w="full"
        >
          Claim Rewards
        </Button>

        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!isLoading
                ? hasWon
                  ? 'Congratulations Journeyman!'
                  : "That's a wrap Journeyman!"
                : 'Revealing Results'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isLoading ? (
                <Center flexDir="column" minH="200px">
                  <Spinner />
                  <Text mt={4}>Fetching rewards</Text>
                </Center>
              ) : (
                <Box>
                  {hasWon ? (
                    <Text>
                      You&apos;ve hit your staking target and secured an
                      additional{' '}
                      <Box as="span" fontWeight="semibold">
                        {fmtRewards}
                      </Box>{' '}
                      MNT from the Double Down pool.
                    </Text>
                  ) : (
                    <Text>Sorry journeyman, you have no rewards to claim.</Text>
                  )}
                </Box>
              )}
            </ModalBody>
            <ModalFooter>
              {!isLoading && hasWon ? (
                <Button
                  w="full"
                  size="xl"
                  variant="primary"
                  colorScheme="accent"
                  onClick={batchClaimRewards}
                  isLoading={isClaiming}
                  isDisabled={!batchClaimRewards}
                >
                  {hasClaimedRewards ? 'Already Claimed!' : 'Claim Rewards'}
                </Button>
              ) : !isLoading ? (
                <Button variant="ghost" onClick={handleClose}>
                  Close
                </Button>
              ) : null}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </VStack>
  )
}

export default MoeJourneyRewardReveal
