import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import TradingViewChart from 'components/TradingViewChart'
import Web3Button from 'components/Web3Button'
import { LB_SWAP_ROUTER } from 'constants/addresses'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import useGetTradeRoutes from 'hooks/swap/useGetTradeRoutes'
import { useSwap } from 'hooks/swap/useSwap'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { CandlestickIcon } from 'theme/icons'
import { Route } from 'types/router'
import { formattedNum } from 'utils/format'
import { trackSwap } from 'utils/measure'
import { isQuoteEqual } from 'utils/router'
import { tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrency,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import TradeCurrencyInputs from './TradeCurrencyInputs'
import TradeCurrencyShortcuts from './TradeCurrencyShortcuts'
import TradeDetails from './TradeDetails'
import TradeRefetchView from './TradeRefetchView'

const Trade = () => {
  const { isConnected } = useAccount()
  const location = useLocation()
  const chainId = useChainId()

  const [isChartVisible, setIsChartVisible] = useState(false)

  // Decode search params
  const [searchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses
  })

  // Input/output currencies
  const isExactIn = true
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(CNATIVE.onChain(chainId))
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const inputCurrency = useMemo(
    () => selectedInputCurrency ?? inputCurrencyParam,
    [selectedInputCurrency, inputCurrencyParam]
  )
  const outputCurrency = useMemo(
    () => selectedOutputCurrency ?? outputCurrencyParam,
    [selectedOutputCurrency, outputCurrencyParam]
  )

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const userTypedCurrency = isExactIn ? inputCurrency : outputCurrency
  const typedTokenAmount = useMemo(
    () =>
      wrappedCurrencyAmount(
        tryParseAmount(typedValue, userTypedCurrency),
        chainId
      ),
    [typedValue, userTypedCurrency, chainId]
  )

  // Get token USD prices
  const inputWrappedCurrencyAddress = wrappedCurrency(inputCurrency, chainId)
    ?.address
  const outputWrappedCurrencyAddress = wrappedCurrency(outputCurrency, chainId)
    ?.address
  const { data: tokenPrices } = useGetTokensUsdPrice({
    tokenAddresses:
      inputWrappedCurrencyAddress && outputWrappedCurrencyAddress
        ? [inputWrappedCurrencyAddress, outputWrappedCurrencyAddress]
        : []
  })
  const inputCurrencyUsdPrice =
    tokenPrices?.[inputWrappedCurrencyAddress?.toLowerCase() || '']
  const outputCurrencyUsdPrice =
    tokenPrices?.[outputWrappedCurrencyAddress?.toLowerCase() || '']

  // Fetch trades
  const refetchInterval = 30000 // 30 seconds
  const [isRefreshQuoteEnabled, setIsRefreshQuoteEnabled] = useState(true)
  const {
    data: quotes = [],
    isFetching: isFetchingQuotes,
    lastFetchTime: quoteLastFetchTime,
    numberOfQuotesLoading,
    refetch: refetchQuotes
  } = useGetTradeRoutes({
    chainId,
    inputCurrency,
    isExactIn,
    outputCurrency,
    refetchInterval: isRefreshQuoteEnabled ? refetchInterval : 0,
    typedTokenAmount
  })

  const [userSelectedTradeRoute, setUserSelectedTradeRoute] = useState<
    Route | undefined
  >()

  const selectedTradeRoute =
    userSelectedTradeRoute ?? (quotes.length > 0 ? quotes[0] : undefined)

  useEffect(() => {
    setUserSelectedTradeRoute((_quote) => {
      if (!_quote) return undefined
      return quotes.find((quote) => isQuoteEqual(quote, _quote))
    })
  }, [quotes])

  const isLiquidityInsufficient =
    !isFetchingQuotes && !selectedTradeRoute && !!typedValue

  // Get amounts usd prices
  const inputAmountUsdPrice =
    selectedTradeRoute && inputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountIn.formatted) * inputCurrencyUsdPrice
      : undefined
  const outputAmountUsdPrice =
    selectedTradeRoute && outputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountOut.formatted) * outputCurrencyUsdPrice
      : undefined
  const priceImpactInUsd =
    inputAmountUsdPrice && outputAmountUsdPrice
      ? ((outputAmountUsdPrice - inputAmountUsdPrice) / inputAmountUsdPrice) *
        100
      : undefined

  // Slippage
  const { slippageSettings } = useSlippageSettings()
  const slippageBps = Math.trunc(slippageSettings.swap * 100)

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = priceImpactInUsd
    ? priceImpactInUsd >= 0
      ? 0
      : Math.abs(priceImpactInUsd)
    : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && selectedTradeRoute
      ? Number(inputBalance.data.formatted) >=
        Number(selectedTradeRoute.amountIn.formatted)
      : true

  // Approval
  const {
    approvalType,
    approveAsync,
    isApproved,
    isApproving,
    isLoadingAllowance,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: selectedTradeRoute?.amountIn.value,
    spender: LB_SWAP_ROUTER[chainId],
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Approve click handler
  const onApproveClick = approveAsync
    ? async () => {
        setIsRefreshQuoteEnabled(false)
        try {
          await approveAsync()
        } finally {
          setIsRefreshQuoteEnabled(true)
        }
      }
    : undefined

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    setIsRefreshQuoteEnabled(true)
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Reset approval on currency change
  useEffect(() => {
    resetApproval()
  }, [inputCurrency, selectedTradeRoute?.amountIn.value, resetApproval])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingQuotes &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const {
    error: swapError,
    forceSwapAsync,
    isSimulating: isSimulatingSwap,
    isSwapping,
    resetSwap,
    swapAsync
  } = useSwap({
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    enabled: isSwapEnabled,
    isExactIn,
    onSwapSuccess,
    route: selectedTradeRoute,
    slippageBps
  })

  // Swap click handler
  const onSwapClick = async () => {
    setIsRefreshQuoteEnabled(false)
    try {
      await (forceSwapAsync ?? swapAsync)?.()
      trackSwap(
        inputCurrency?.symbol,
        outputCurrency?.symbol,
        selectedTradeRoute?.aggregator,
        inputAmountUsdPrice
      )
    } catch {
      setIsRefreshQuoteEnabled(true)
    }
  }

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Update inputs and currencies when chain changes
  useEffect(() => {
    setInputCurrency(!!inputCurrencyAddr ? undefined : CNATIVE.onChain(chainId))
    setOutputCurrency(undefined)
    setTypedValue('')
  }, [chainId, inputCurrencyAddr])

  // Action handlers
  const onChangeSwapDirectionClick = useCallback(() => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    setInputCurrency(newInputCurrency)
    setOutputCurrency(newOutputCurrency)
  }, [inputCurrency, outputCurrency])

  return (
    <Flex mt={{ base: 6, md: 10 }} mb="200px" justify="center">
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Grid
        gap={{ base: 0, lg: 8 }}
        w="full"
        alignItems="flex-start"
        maxW={isChartVisible ? '1400px' : '550px'}
        templateColumns={
          isChartVisible
            ? {
                base: 'minmax(0, 1fr)',
                lg: 'minmax(0, 7fr) minmax(0, 5fr)'
              }
            : '1fr'
        }
      >
        {isChartVisible ? (
          <Box
            order={{ base: 1, md: 0 }}
            mt={{ base: 4, md: 0 }}
            px={{ base: 4, md: 0 }}
          >
            <Box
              w="full"
              h="full"
              border="1px solid"
              borderColor="border"
              borderRadius={{ base: 'md', md: '2xl' }}
              overflow="hidden"
              bg="white"
              _dark={{ bg: '#151822' }}
              p={1}
            >
              {inputCurrency && outputCurrency ? (
                <TradingViewChart
                  inputCurrency={inputCurrency}
                  outputCurrency={outputCurrency}
                />
              ) : (
                <Center h={{ base: '400px', md: '600px' }}>
                  <Text>Select a token pair to view the chart</Text>
                </Center>
              )}
            </Box>
          </Box>
        ) : null}
        <Flex
          width="full"
          flexDir="column"
          gap={4}
          bg="bgCard"
          p={4}
          borderRadius={{ base: 'lg', md: '2xl' }}
        >
          <HStack>
            <Heading size="md">
              <Trans>Swap</Trans>
            </Heading>

            <Spacer />

            {!!inputCurrency && !!outputCurrency ? (
              <IconButton
                data-cy="toggle-chart-button"
                aria-label="Toggle chart"
                size="sm"
                borderRadius="full"
                boxSize={10}
                bg="bgSecondary"
                variant="ghost"
                icon={<CandlestickIcon w="24px" h="24px" />}
                onClick={() => setIsChartVisible(!isChartVisible)}
              />
            ) : null}
            {!!selectedTradeRoute ? (
              <TradeRefetchView
                isRefreshQuoteEnabled={isRefreshQuoteEnabled}
                lastFetchTime={quoteLastFetchTime}
                refetchInterval={refetchInterval}
                onRefetchClick={refetchQuotes}
              />
            ) : null}
            <SlippageSettingsPicker
              type="swap"
              iconButtonProps={{
                'aria-label': 'open settings',
                bg: 'bgSecondary',
                border: 0,
                borderRadius: 'full',
                boxShadow: 'none',
                size: 'md'
              }}
            />
          </HStack>

          <TradeCurrencyInputs
            inputCurrencyProps={{
              amountUsd: inputAmountUsdPrice
                ? '~' +
                  formattedNum(inputAmountUsdPrice, {
                    allowDecimalsOver1000: true,
                    places: 2,
                    usd: true
                  })
                : undefined,
              balance: inputBalance.data?.formatted,
              currency: inputCurrency,
              currencyAddress: inputCurrencyAddress,
              heading: t`From`,
              onCurrencyChange: (currency) => {
                const cAddress = currency.isToken ? currency.address : undefined
                const outputCurrencyAddress = outputCurrency?.isToken
                  ? outputCurrency.address
                  : undefined
                currency.symbol === outputCurrency?.symbol &&
                cAddress === outputCurrencyAddress
                  ? onChangeSwapDirectionClick()
                  : setInputCurrency(currency)
              },
              onValueChange: (value) => {
                // setIsExactIn(true)
                setTypedValue(value)
              },
              rightElement: inputCurrency ? (
                <MaxButton
                  data-cy="trade-currency-input-max-button"
                  borderRadius="full"
                  balance={inputBalance.data?.formatted}
                  onClick={() => {
                    // setIsExactIn(true)
                    setTypedValue(inputBalance.data?.formatted ?? '')
                  }}
                />
              ) : (
                <TradeCurrencyShortcuts
                  otherSelectedCurrency={outputCurrency}
                  onCurrencyClick={setInputCurrency}
                />
              ),
              value: wrapUnwrapState
                ? typedValue
                : isExactIn
                  ? typedValue
                  : selectedTradeRoute?.amountIn.formatted ?? '',
              wrapRightElementIfNeeded: !inputCurrency
            }}
            outputCurrencyProps={{
              amountUsd:
                outputAmountUsdPrice && priceImpactInUsd
                  ? `~${formattedNum(outputAmountUsdPrice, {
                      allowDecimalsOver1000: true,
                      places: 2,
                      usd: true
                    })} (${priceImpactInUsd.toFixed(2)}%)`
                  : undefined,
              balance: outputBalance.data?.formatted,
              currency: outputCurrency,
              currencyAddress: outputCurrencyAddress,
              heading: t`To`,
              isBalanceButtonDisabled: true,
              isNumericalInputDisabled: true,
              onCurrencyChange: (currency) => {
                const cAddress = currency.isToken ? currency.address : undefined
                const inputCurrencyAddress = inputCurrency?.isToken
                  ? inputCurrency.address
                  : undefined

                currency.symbol === inputCurrency?.symbol &&
                inputCurrencyAddress === cAddress
                  ? onChangeSwapDirectionClick()
                  : setOutputCurrency(currency)
              },
              onValueChange: (value) => {
                // setIsExactIn(false)
                setTypedValue(value)
              },
              rightElement: !outputCurrency ? (
                <TradeCurrencyShortcuts
                  otherSelectedCurrency={inputCurrency}
                  onCurrencyClick={setOutputCurrency}
                />
              ) : undefined,
              value: wrapUnwrapState
                ? typedValue
                : isExactIn
                  ? selectedTradeRoute?.amountOut.formatted ?? ''
                  : typedValue,
              wrapRightElementIfNeeded: !outputCurrency
            }}
            onChangeSwapDirectionClick={onChangeSwapDirectionClick}
            isChangeSwapDirectionDisabled={isFetchingQuotes || isSwapping}
            bottomContent={
              <Box w="full" pt={selectedTradeRoute ? 4 : 0}>
                {selectedTradeRoute ? (
                  <TradeDetails
                    isExactIn={isExactIn}
                    trades={quotes}
                    selectedTrade={selectedTradeRoute}
                    slippageBps={slippageBps}
                    onTradeSelected={(trade) => {
                      resetSwap?.()
                      resetApproval()
                      setUserSelectedTradeRoute(trade)
                    }}
                    outputCurrencyUsdPrice={outputCurrencyUsdPrice}
                    priceImpact={priceImpact}
                    numberOfQuotesLoading={numberOfQuotesLoading}
                  />
                ) : null}
                <Flex flexDir="column" pt={4} gap={4}>
                  {onApproveClick &&
                  !isApproved &&
                  hasEnoughInputCurrency &&
                  !isFetchingQuotes ? (
                    <ApproveTokenButton
                      data-cy="approve-button"
                      amount={selectedTradeRoute?.amountIn.formatted}
                      currencySymbol={inputCurrency?.symbol}
                      approvalType={approvalType}
                      onApprovalTypeSelect={setApprovalType}
                      isLoading={isApproving}
                      onClick={onApproveClick}
                    />
                  ) : null}
                  {wrapUnwrapState && !!wrapUnwrap ? (
                    <Web3Button
                      data-cy="wrap-unwrap-button"
                      variant="primary"
                      colorScheme="accent"
                      w="full"
                      size="xl"
                      isLoading={isLoadingWrapUnwrap}
                      loadingText={wrapUnwrapState}
                      onClick={() => wrapUnwrap?.()}
                    >
                      {wrapUnwrapState}
                    </Web3Button>
                  ) : (
                    <Web3Button
                      data-cy="swap-button"
                      variant="primary"
                      colorScheme={
                        (isPriceImpactHigh && isConnected) || swapError
                          ? 'red'
                          : 'accent'
                      }
                      w="full"
                      size="xl"
                      isLoading={
                        isFetchingQuotes ||
                        isSwapping ||
                        isSimulatingSwap ||
                        isLoadingAllowance
                      }
                      loadingText={
                        isFetchingQuotes
                          ? t`Fetching best trade`
                          : isSimulatingSwap
                            ? t`Fetching swap data`
                            : isLoadingAllowance
                              ? t`Fetching allowance`
                              : t`Waiting for confirmation`
                      }
                      isDisabled={
                        !typedTokenAmount ||
                        !isSwapEnabled ||
                        isLiquidityInsufficient ||
                        isSimulatingSwap ||
                        (isPriceImpactHigh && isSafeModeEnabled) ||
                        (!swapAsync && !forceSwapAsync)
                      }
                      onClick={onSwapClick}
                    >
                      {isLiquidityInsufficient ? (
                        <Trans>Insufficient liquidity for this trade</Trans>
                      ) : hasEnoughInputCurrency ? (
                        isPriceImpactHigh ? (
                          isSafeModeEnabled ? (
                            <Trans>Safe Mode Activated</Trans>
                          ) : (
                            <Trans>Swap Anyway</Trans>
                          )
                        ) : swapError ? (
                          <Trans>Swap Anyway</Trans>
                        ) : (
                          <Trans>Swap</Trans>
                        )
                      ) : (
                        <Trans>Not enough {inputCurrency?.symbol}</Trans>
                      )}
                    </Web3Button>
                  )}
                  {swapError ? (
                    <CopyableError
                      textProps={{ mt: -2 }}
                      error={swapError.message}
                      summary={swapError.summary || ''}
                    />
                  ) : null}

                  {isPriceImpactHigh && isSafeModeEnabled ? (
                    <Text
                      fontSize="sm"
                      textColor="textSecondary"
                      textAlign="center"
                      mt={-2}
                    >
                      <Trans>
                        Safe mode prevents high price impact trade. It can be
                        accessed in Settings.
                      </Trans>
                    </Text>
                  ) : null}
                </Flex>
              </Box>
            }
          />

          {outputCurrency && outputCurrency.isToken ? (
            <Center w="full">
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default Trade
