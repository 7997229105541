import {
  Box,
  Button,
  ButtonProps,
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import NumericalInput from 'components/NumericalInput'
import SlippageInputRow from 'components/SlippageInputRow'
import useChainId from 'hooks/useChainId'
import { defaultTransactionDeadline } from 'hooks/useGetTransactionDeadline'
import React, { useMemo } from 'react'
import {
  useIsRemoveLiquidityInNativeCurrencyEnabled,
  useIsSafeModeEnabled,
  useSlippageSettings,
  useUserTransactionDeadline
} from 'state/settings/hooks'
import { SlippageType } from 'state/settings/reducer'
import { SettingsIcon } from 'theme/icons'
import { wrappedCurrency } from 'utils/wrappedCurrency'

export type SlippageSettingsPickerType = 'swap' | 'poolV1' | 'poolV2'

interface SlippageSettingsPickerProps {
  type: SlippageSettingsPickerType
  iconButtonProps?: ButtonProps
}

const SlippageSettingsPicker = ({
  iconButtonProps,
  type
}: SlippageSettingsPickerProps) => {
  const { slippageSettings, updateSlippage } = useSlippageSettings()
  const { setUserTransactionDeadline, userTransactionDeadline } =
    useUserTransactionDeadline()
  const { isSafeModeEnabled, updateIsSafeModeEnabled } = useIsSafeModeEnabled()
  // const { isDedicatedRPCSelected, updateDedicatedRPC } = useIsDedicatedRPC()
  const {
    isRemoveLiquidityInNativeCurrencyEnabled,
    updateIsRemoveLiquidityInNativeCurrencyEnabled
  } = useIsRemoveLiquidityInNativeCurrencyEnabled()

  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)
  const wrappedNativeCurrency = wrappedCurrency(nativeCurrency, chainId)

  const riskySlippageTolerance = useMemo(() => {
    switch (type) {
      case 'swap':
        return slippageSettings.swap >= 2 ? slippageSettings.swap : undefined
      case 'poolV1':
        return slippageSettings.v1 >= 2 ? slippageSettings.v1 : undefined
      case 'poolV2':
        return slippageSettings.liquidityAmountV2 >= 2
          ? slippageSettings.liquidityAmountV2
          : slippageSettings.liquidityPriceV2 >= 2
            ? slippageSettings.liquidityPriceV2
            : undefined
    }
  }, [slippageSettings, type])

  return (
    <Popover>
      <PopoverTrigger>
        {riskySlippageTolerance ? (
          <Button
            aria-label="open settings"
            data-cy="settings-button"
            rightIcon={<SettingsIcon ml={1} fill="yellow.700" />}
            variant="outline"
            size="lg"
            borderRadius={{ base: 'md', md: '2xl' }}
            {...iconButtonProps}
            borderColor="transparent"
            color="yellow.700"
            bg="yellowBar"
            _hover={{ opacity: 0.5 }}
          >
            <Trans>{riskySlippageTolerance}% slippage</Trans>
          </Button>
        ) : (
          <IconButton
            aria-label="open settings"
            data-cy="settings-button"
            icon={<SettingsIcon />}
            size="sm"
            bg="bgSecondary"
            boxSize="40px"
            borderRadius="10px"
            boxShadow="element"
            {...iconButtonProps}
          />
        )}
      </PopoverTrigger>
      {/* https://github.com/chakra-ui/chakra-ui/issues/4109#issuecomment-1353262044 */}
      <PopoverContent
        w={{ base: '350px', sm: '375px' }}
        rootProps={{ style: { transform: 'scale(0)' } }}
      >
        <PopoverArrow />
        <PopoverCloseButton data-cy="settings-close-button" />
        <PopoverHeader>Settings</PopoverHeader>
        <PopoverBody data-cy="settings-popover-body">
          <VStack spacing={4} align="flex-start">
            {type === 'poolV1' ? (
              <SlippageInputRow
                title={t`Normal slippage tolerance (V1)`}
                placeholder="0.5"
                tooltipLabel={t`Normal slippage tolerance is applied for trades that include v1 pools. Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%.`}
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.v1.toString()}
                transactionFailThreshold={0.5}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.V1, slippage)
                }
              />
            ) : null}
            {type === 'swap' ? (
              <SlippageInputRow
                title={t`Slippage tolerance`}
                placeholder="0.05"
                tooltipLabel={t`Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%.`}
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.swap.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.SWAP, slippage)
                }
              />
            ) : null}
            {type === 'poolV2' ? (
              <SlippageInputRow
                title={t`Amount slippage tolerance`}
                placeholder="0.1"
                tooltipLabel={t`Your add/remove liquidity transaction will revert if the amount changes unfavorably by more than this percentage. Default is 0.5%.`}
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.liquidityAmountV2.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.LIQUIDITY_AMOUNT_V2, slippage)
                }
              />
            ) : null}
            {type === 'poolV2' ? (
              <SlippageInputRow
                title={t`Price slippage tolerance`}
                placeholder="0.5"
                tooltipLabel={t`Your add liquidity transaction will revert if current active price changes by more than this percentage. Default is 0.5%.`}
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.liquidityPriceV2.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.LIQUIDITY_PRICE_V2, slippage)
                }
              />
            ) : null}
            <VStack align="flex-start">
              <Text fontSize="sm" color="textSecondary">
                <Trans>Transaction Deadline</Trans>
              </Text>
              <InputGroup>
                <NumericalInput
                  placeholder={defaultTransactionDeadline.toString()}
                  inputType="integer"
                  value={userTransactionDeadline}
                  onValueChange={setUserTransactionDeadline}
                />
                <InputRightElement w="fit-content" mr={4}>
                  <Trans>minutes</Trans>
                </InputRightElement>
              </InputGroup>
            </VStack>
            {type === 'swap' ? (
              <VStack align="flex-start" spacing={1}>
                <Text fontSize="sm" color="textSecondary">
                  <Trans>Safe Mode</Trans>
                </Text>
                <HStack>
                  <Switch
                    data-cy="safe-mode-switch"
                    colorScheme="accent"
                    size="lg"
                    isChecked={isSafeModeEnabled}
                    onChange={() => updateIsSafeModeEnabled(!isSafeModeEnabled)}
                  />
                  <Box as="span" fontSize="sm" textColor="textSecondary">
                    <Trans>
                      Prevent high price impact trades. Disable at your own
                      risk.{' '}
                      <Link
                        aria-label="Learn more about Price Impact, Slippage, and Safe Mode"
                        href="https://support.lfj.gg/en/articles/6735950-price-impact-slippage-and-safe-mode"
                        isExternal
                        fontSize="sm"
                        textColor="bgInteractive"
                      >
                        Learn more.
                      </Link>
                    </Trans>
                  </Box>
                </HStack>
              </VStack>
            ) : null}
            {/* <VStack align="flex-start" spacing={1}>
              <Text fontSize="sm" color="textSecondary">
                <Trans>Use Dedicated RPCs</Trans>
              </Text>
              <HStack>
                <Switch
                  colorScheme="accent"
                  size="lg"
                  isChecked={isDedicatedRPCSelected}
                  onChange={() => updateDedicatedRPC(!isDedicatedRPCSelected)}
                />
                <Box as="span" fontSize="sm" textColor="textSecondary">
                  <Trans>Dedicated RPC endpoints provided by Infura</Trans>
                </Box>
              </HStack>
            </VStack> */}
            {type === 'poolV2' ? (
              <VStack align="flex-start" spacing={1}>
                <Text fontSize="sm" color="textSecondary">
                  <Trans>Always remove liquidity in native currency</Trans>
                </Text>
                <HStack>
                  <Switch
                    colorScheme="accent"
                    size="lg"
                    isChecked={isRemoveLiquidityInNativeCurrencyEnabled}
                    onChange={() =>
                      updateIsRemoveLiquidityInNativeCurrencyEnabled(
                        !isRemoveLiquidityInNativeCurrencyEnabled
                      )
                    }
                  />
                  <Box as="span" fontSize="sm" textColor="textSecondary">
                    <Trans>
                      Unwrap {wrappedNativeCurrency?.symbol} when withdrawing
                      liquidity
                    </Trans>
                  </Box>
                </HStack>
              </VStack>
            ) : null}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SlippageSettingsPicker
