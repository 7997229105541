import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Text,
  VStack
} from '@chakra-ui/react'
import TrialsBanner from 'assets/moeJourney/trials-banner.png'
import PageContainer from 'components/PageContainer'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetIsOptedIn from 'hooks/moeJourney/useGetIsOptedIn'
import useGetJourneymanRank from 'hooks/moeJourney/useGetJourneymanRank'
import React from 'react'
import { useAccount } from 'wagmi'

import { JOURNEYMAN_TRIALS_EPOCH } from './constants'
import { DIVISIONS } from './constants'
import JourneyBadge from './JourneyBadge'
import MoeJourneyOptInView from './MoeJourneyOptInView'
import MoeJourneyRewardReveal from './MoeJourneyRewardReveal'
import PodiumSection from './PodiumSection'

const MoeJourney = () => {
  const { isConnected } = useAccount()
  const { data, refetch: refetchJourneymanRank } = useGetJourneymanRank()

  const { data: isOptedIn, refetch: refetchIsOptedIn } = useGetIsOptedIn({
    epoch: JOURNEYMAN_TRIALS_EPOCH
  })

  return (
    <PageContainer mb="100px">
      <Image
        pos="relative"
        borderRadius="2xl"
        h={{ base: '200px', md: '400px' }}
        w="full"
        src={TrialsBanner}
        objectFit="cover"
      />

      <VStack mt={{ base: 10, md: '100px' }}>
        <VStack maxW="700px">
          <Heading size="2xl" fontWeight="black">
            Journeyman Trials
          </Heading>

          <Flex
            mt={{ base: 4, md: 6 }}
            gap={{ base: 4, md: 6 }}
            h={{ base: 'auto', md: '110px' }}
            flexDir={{ base: 'column', md: 'row' }}
            w={{ base: 'full', md: 'auto' }}
          >
            <HStack
              h={{ base: 'auto', md: 'full' }}
              px={{ base: 4, md: 6 }}
              bg="bgAlternative"
              _dark={{ bg: 'bgCard' }}
              borderRadius="xl"
              spacing={4}
              py={{ base: 4, md: 0 }}
            >
              <JourneyBadge type={data?.division.slug || 'canvas'} />
              <VStack align="flex-start" spacing={{ base: 0, md: 1 }}>
                <TouchFriendlyTooltip
                  label={
                    <VStack align="flex-start" spacing={2} py={1}>
                      {DIVISIONS.map((division) => (
                        <HStack key={division.slug}>
                          <JourneyBadge type={division.slug} boxSize={6} />
                          <Text
                            textColor="white"
                            _dark={{ textColor: 'black' }}
                          >
                            {division.slug.charAt(0).toUpperCase() +
                              division.slug.slice(1)}{' '}
                            Mantle
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  }
                >
                  <HStack spacing={1}>
                    <Text fontSize="sm">Division</Text>
                    <InfoOutlineIcon boxSize={3} />
                  </HStack>
                </TouchFriendlyTooltip>
                <Text fontSize="2xl" fontWeight="black">
                  {data
                    ? data.division.slug.charAt(0).toUpperCase() +
                      data.division.slug.slice(1)
                    : '--'}
                </Text>
              </VStack>
            </HStack>

            <Grid templateColumns="repeat(2, 1fr)" gap={{ base: 4, md: 6 }}>
              <HStack
                h={{ base: 'auto', md: 'full' }}
                px={6}
                bg="bgAlternative"
                _dark={{ bg: 'bgCard' }}
                borderRadius="xl"
                spacing={4}
                py={{ base: 4, md: 0 }}
              >
                <VStack align="flex-start" spacing={1}>
                  <Text fontSize="sm">Division Rank</Text>
                  <Text fontWeight="black">
                    <Box as="span" fontSize="2xl">
                      {data?.rankInDivision || '--'}
                    </Box>{' '}
                    / {data?.totalUsersInDivision || '--'}
                  </Text>
                </VStack>
              </HStack>

              <HStack
                h={{ base: 'auto', md: 'full' }}
                px={6}
                bg="bgAlternative"
                _dark={{ bg: 'bgCard' }}
                borderRadius="xl"
                spacing={4}
                py={{ base: 4, md: 0 }}
              >
                <VStack align="flex-start" spacing={1}>
                  <Text fontSize="sm">Global Rank</Text>
                  <Text fontWeight="black">
                    <Box as="span" fontSize="2xl">
                      {data?.rank || '--'}
                    </Box>{' '}
                    / {data?.totalUsers || '--'}
                  </Text>
                </VStack>
              </HStack>
            </Grid>
          </Flex>

          <Text mt={6}>
            Merchant Moe&apos;s Journeymen Trials have begun. Explore and engage
            with Moe&apos;s exchange, earn your mantle, and rise through the
            ranks to become one of Moe&apos;s top journeymen. The higher your
            rank, the bigger the rewards.
          </Text>
          <Text mt={2}>
            <Box as="span" fontWeight="bold">
              How the Trials Work:
            </Box>{' '}
            Enter the Trials using the Sign-Up button below and compete to climb
            the ranks of the Journeyman Trials. Your ranking is determined by
            your activity on the Merchant Moe DEX, with your final rank deciding
            your share of the rewards at the end of Moe&apos;s Rager campaign.
          </Text>
          <Text mt={2}>
            With 8 Mantle Divisions, from the prestigious Emerald Mantle to the
            humble Canvas Mantle, the path to glory is clear. Rewards increase
            with higher divisions, and your exact rank decides your share of the
            MNT prize pool. While the exact ranking criteria remain a mystery,
            one thing is certain: consistent engagement on the exchange is your
            ticket to the top of the leaderboards and maximizing your rewards.{' '}
            <Box
              as="a"
              href="https://merchantmoe.substack.com/p/moes-rager-2-months-1m-mnt-lets-fing"
              textColor="accent.500"
              textDecoration="underline"
              _hover={{ opacity: 0.8 }}
              target="_blank"
            >
              Read More.
            </Box>
          </Text>
        </VStack>

        {isConnected ? <MoeJourneyRewardReveal /> : null}

        {isOptedIn ? (
          data && (
            <PodiumSection
              division={data.division}
              rankInDivision={data.rankInDivision}
              totalUsersInDivision={data.totalUsersInDivision}
            />
          )
        ) : (
          <MoeJourneyOptInView
            onOptInSuccess={() => {
              refetchIsOptedIn()
              refetchJourneymanRank()
            }}
          />
        )}
      </VStack>
    </PageContainer>
  )
}

export default MoeJourney
