import { Currency } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { Route } from 'types/router'

import useGetQuoteFromBarn from './useGetQuoteFromBarn'

interface UseGetQuotesFromBarnProps {
  enabled: boolean
  isExactIn: boolean
  amount?: string
  currencyIn?: Currency
  currencyOut?: Currency
  refetchInterval?: number
}

const useGetQuotesFromBarn = (props: UseGetQuotesFromBarnProps) => {
  const jarQuote = useGetQuoteFromBarn({ ...props, aggregator: 'jar' })
  const odosQuote = useGetQuoteFromBarn({
    ...props,
    aggregator: 'odos',
    enabled: props.enabled && props.isExactIn
  })

  const quotes = useMemo(() => {
    const quotes = [jarQuote.data, odosQuote.data].filter(Boolean) as Route[]

    return quotes.sort((a, b) => {
      if (a.amountOut.value > b.amountOut.value) return -1
      if (a.amountOut.value < b.amountOut.value) return 1

      // If amounts are equal, prioritize jar
      if (a.amountOut.value === b.amountOut.value) {
        if (a.aggregator === 'jar') return -1
        if (b.aggregator === 'jar') return 1
      }
      return 0
    })
  }, [jarQuote.data, odosQuote.data])

  const numberOfQuotesLoading = useMemo(() => {
    return Number(jarQuote.isLoading) + Number(odosQuote.isLoading)
  }, [jarQuote.isLoading, odosQuote.isLoading])

  return {
    data: quotes,
    isFetching: jarQuote.isFetching && odosQuote.isFetching,
    lastFetchTime: Math.max(jarQuote.lastFetchTime, odosQuote.lastFetchTime),
    numberOfQuotesLoading,
    refetch: () => {
      jarQuote.refetch()
      odosQuote.refetch()
    }
  }
}

export default useGetQuotesFromBarn
