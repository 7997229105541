import { Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import { MerchantMoeChainId } from 'constants/chains'
import useDebounce from 'hooks/useDebounce'
import {
  getIsNativeInAndWrappedNativeOut,
  getIsWrappedNativeInAndNativeOut
} from 'utils/wrappedCurrency'

import useGetQuotesFromBarn from './useGetQuotesFromBarn'

interface UseGetTradeRoutesProps {
  chainId: MerchantMoeChainId
  isExactIn: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  refetchInterval?: number
  typedTokenAmount?: TokenAmount
}

const useGetTradeRoutes = ({
  chainId,
  inputCurrency,
  isExactIn,
  outputCurrency,
  refetchInterval,
  typedTokenAmount
}: UseGetTradeRoutesProps) => {
  const debouncedAmount = useDebounce(typedTokenAmount, 500)
  const debouncedInputCurrency = useDebounce(inputCurrency, 250)
  const debouncedOutputCurrency = useDebounce(outputCurrency, 250)

  const isNativeInAndWrappedNativeOut = getIsNativeInAndWrappedNativeOut(
    chainId,
    debouncedInputCurrency,
    debouncedOutputCurrency
  )

  const isWrappedNativeInAndNativeOut = getIsWrappedNativeInAndNativeOut(
    chainId,
    debouncedInputCurrency,
    debouncedOutputCurrency
  )

  return useGetQuotesFromBarn({
    amount: debouncedAmount?.raw?.toString(),
    currencyIn: debouncedInputCurrency,
    currencyOut: debouncedOutputCurrency,
    enabled: !isNativeInAndWrappedNativeOut && !isWrappedNativeInAndNativeOut,
    isExactIn,
    refetchInterval
  })
}

export default useGetTradeRoutes
