import { Hex, zeroAddress } from 'viem'

import { MerchantMoeChainId } from './chains'

export const LB_SWAP_ROUTER: {
  [key in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.FUJI]: '0x45A62B090DF48243F12A21897e7ed91863E2c86b',
  [MerchantMoeChainId.MANTLE]: '0x45A62B090DF48243F12A21897e7ed91863E2c86b'
}

export const LB_SWAP_ROUTER_LOGIC: {
  [key in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.FUJI]: '0xB35033d71cF5E13cAB5eB8618260F94363Dff9Cf',
  [MerchantMoeChainId.MANTLE]: '0xB35033d71cF5E13cAB5eB8618260F94363Dff9Cf'
}

export const OKX_TOKEN_SPENDER: {
  [key in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.FUJI]: zeroAddress,
  [MerchantMoeChainId.MANTLE]: '0x57df6092665eb6058de53939612413ff4b09114e'
}

export const ODOS_ROUTER: {
  [key in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.FUJI]: zeroAddress,
  [MerchantMoeChainId.MANTLE]: '0xD9F4e85489aDCD0bAF0Cd63b4231c6af58c26745'
}

export const FORWARDER_LOGIC_ADDRESS: {
  [key in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.FUJI]: zeroAddress,
  [MerchantMoeChainId.MANTLE]: '0xC04f291347D21DC663f7646056db22bFf8CE8430'
}
