import { Route, Swap, Token } from 'types/router'

export const traverseRoute = (token: Token): Swap[][] => {
  if (!token.swaps || token.swaps.length === 0) {
    return [[]]
  }

  const paths: Swap[][] = []

  for (const swap of token.swaps) {
    const subPaths = traverseRoute(swap.tokenOut)
    if (subPaths.length === 0) {
      paths.push([swap])
    } else {
      for (const subPath of subPaths) {
        paths.push([swap, ...subPath])
      }
    }
  }

  return paths
}

export const isQuoteEqual = (quote1: Route, quote2: Route) => {
  const basicPropsEqual =
    quote1.aggregator === quote2.aggregator &&
    quote1.amountOut.value === quote2.amountOut.value &&
    quote1.amountIn.value === quote2.amountIn.value &&
    quote1.tokenIn.address === quote2.tokenIn.address &&
    quote1.tokenOut.address === quote2.tokenOut.address

  if (!basicPropsEqual) return false

  const swaps1 = quote1.tokenIn.swaps || []
  const swaps2 = quote2.tokenIn.swaps || []

  if (swaps1.length !== swaps2.length) return false

  return swaps1.every((swap1, index) => {
    const swap2 = swaps2[index]
    if (!swap2) return false

    const basePropsEqual =
      swap1.amountBp === swap2.amountBp &&
      swap1.pair.address === swap2.pair.address &&
      swap1.pair.type === swap2.pair.type &&
      swap1.pair.tokenX === swap2.pair.tokenX &&
      swap1.pair.tokenY === swap2.pair.tokenY &&
      swap1.tokenIn.address === swap2.tokenIn.address &&
      swap1.tokenOut.address === swap2.tokenOut.address

    // Return false if one swap has pair data but the other doesn't
    if (!!swap1.pair.data !== !!swap2.pair.data) {
      return false
    }

    // Compare LB pair data if it exists on both
    if (swap1.pair.data && swap2.pair.data) {
      return (
        basePropsEqual &&
        swap1.pair.data.binStep === swap2.pair.data.binStep &&
        swap1.pair.data.lbType === swap2.pair.data.lbType
      )
    }

    return basePropsEqual
  })
}
