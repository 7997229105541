import { useQuery } from '@tanstack/react-query'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { routerApiClient, routerTestnetClient } from 'constants/dexbarn'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import { useState } from 'react'
import { Aggregator, DexbarnRoute, Route } from 'types/router'
import { getDexbarnChainParam } from 'utils/chains'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

interface UseGetQuoteFromBarnProps {
  aggregator: Aggregator
  enabled: boolean
  isExactIn: boolean
  amount?: string
  currencyIn?: Currency
  currencyOut?: Currency
  refetchInterval?: number
}

const useGetQuoteFromBarn = ({
  aggregator,
  amount,
  currencyIn,
  currencyOut,
  enabled,
  isExactIn,
  refetchInterval
}: UseGetQuoteFromBarnProps) => {
  const chainId = useChainId()
  const chain = useActiveChain()
  const isTestnet = chain.testnet
  const client = isTestnet ? routerTestnetClient : routerApiClient

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  const params =
    tokenIn && tokenOut && amount
      ? {
          amountIn: isExactIn ? amount : undefined,
          amountOut: isExactIn ? undefined : amount,
          tokenIn: tokenIn.address.toLowerCase(),
          tokenOut: tokenOut.address.toLowerCase()
        }
      : undefined

  const [lastFetchTime, setLastFetchTime] = useState(Date.now())
  const {
    data: quote,
    isFetching: isFetchingQuote,
    isLoading: isLoadingQuote,
    refetch: refetchQuote
  } = useQuery<DexbarnRoute, Error, Route | undefined>({
    enabled: enabled && !!params,
    queryFn: async () => {
      setLastFetchTime(Date.now())

      const response = await client.get<DexbarnRoute>(
        `/v2/aggregator/routes/${getDexbarnChainParam(
          chainId
        )}/${aggregator}/quote`,
        { params }
      )

      return response.data
    },
    queryKey: ['GetDexbarnQuote', chainId, params, aggregator],
    refetchInterval,
    select: (data) => {
      if (!currencyIn || !currencyOut) return undefined

      return {
        ...data,
        amountIn: {
          formatted: formatUnits(BigInt(data.amountIn), currencyIn.decimals),
          value: BigInt(data.amountIn)
        },
        amountOut: {
          formatted: formatUnits(BigInt(data.amountOut), currencyOut.decimals),
          value: BigInt(data.amountOut)
        },
        currencyIn,
        currencyOut
      } satisfies Route
    }
  })

  return {
    data: quote,
    isFetching: isFetchingQuote,
    isLoading: isLoadingQuote,
    lastFetchTime,
    refetch: refetchQuote
  }
}

export default useGetQuoteFromBarn
