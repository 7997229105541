import { HStack, Image, Text } from '@chakra-ui/react'
import JoeLogo from 'assets/joe.png'
import OdosLogo from 'assets/odos.png'
import OkxLogo from 'assets/okx.webp'
import React from 'react'
import { Aggregator } from 'types/router'

interface AggregatorTagProps {
  aggregator: Aggregator
}

const AggregatorTag = ({ aggregator }: AggregatorTagProps) => {
  switch (aggregator) {
    case 'jar':
      return (
        <HStack>
          <Image src={JoeLogo} boxSize="20px" />
          <Text fontSize="sm" fontWeight="semibold">
            LFJ
          </Text>
        </HStack>
      )
    case 'okx':
      return (
        <HStack>
          <Image src={OkxLogo} boxSize="20px" />
          <Text fontSize="sm" fontWeight="semibold">
            OKX
          </Text>
        </HStack>
      )
    case 'odos':
      return (
        <HStack>
          <Image src={OdosLogo} boxSize="20px" />
          <Text fontSize="sm" fontWeight="semibold">
            Odos
          </Text>
        </HStack>
      )
  }
}

export default AggregatorTag
